module.exports.expansions = {
  en: {
    title: 'Avalon: The Resistance | Expansions for game',
    description:
      "Enhance 'Avalon: The Resistance' with exciting expansions and extra cards. Learn how each addition can spice up your game sessions and bring new challenges to the table.",
    keywords: ['Expansions', 'Addons', 'Module', 'Rules'],
  },
  ru: {
    title: 'Авалон: Сопротивление | Дополнения для игры',
    description:
      "Улучшите игру 'Авалон: Сопротивление' с помощью захватывающих дополнений и дополнительных карт. Узнайте, как каждое дополнение может оживить ваши игровые сессии и добавить новые вызовы.",
    keywords: ['Дополнения', 'Аддоны', 'Модули', 'Правила'],
  },
  'zh-TW': {
    title: '阿瓦隆：反抗勢力 | 遊戲擴充',
    description:
      '透過令人興奮的擴充包和額外卡牌來強化《阿瓦隆：反抗勢力》。了解每個擴充如何讓你的遊戲更加精彩，帶來新的挑戰。',
    keywords: ['擴充', '附加組件', '模組', '規則'],
  },
  'zh-CN': {
    title: '阿瓦隆：反抗组织 | 游戏扩展',
    description:
      '通过令人兴奋的扩展包和额外卡牌来增强《阿瓦隆：反抗组织》。了解每个扩展如何让你的游戏更加精彩，并带来新的挑战。',
    keywords: ['扩展', '附加组件', '模块', '规则'],
  },
};
