import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-22ea42c5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "snackbar-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!

  return (_ctx.snackbar)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_v_snackbar, {
          modelValue: _ctx.snackbar,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.snackbar) = $event)),
          class: "text-center ma-2"
        }, {
          actions: _withCtx(() => [
            _createVNode(_component_v_btn, {
              color: "pink",
              variant: "text",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.snackbar = false))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('infoMessage.close')), 1)
              ]),
              _: 1
            })
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.text), 1)
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]))
    : _createCommentVNode("", true)
}