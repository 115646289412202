import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3648cd8c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "lobby" }
const _hoisted_2 = { class: "alert-container" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "lobby-header" }
const _hoisted_6 = { class: "list-header mb-5" }
const _hoisted_7 = { class: "game mb-1" }
const _hoisted_8 = { class: "game-container" }
const _hoisted_9 = { class: "game-left" }
const _hoisted_10 = { class: "mr-2 game-name" }
const _hoisted_11 = { class: "game-right" }
const _hoisted_12 = { class: "players-amount mr-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TemporaryAlert = _resolveComponent("TemporaryAlert")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_OptionsPreview = _resolveComponent("OptionsPreview")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_TemporaryAlert, { id: "discord" }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            innerHTML: _ctx.$t('alert.contentdiscord')
          }, null, 8, _hoisted_3)
        ]),
        _: 1
      }),
      _createVNode(_component_TemporaryAlert, { id: "translate" }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            innerHTML: _ctx.$t('alert.contenttranslate')
          }, null, 8, _hoisted_4)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("h1", _hoisted_5, _toDisplayString(_ctx.$t('mainPage.header')), 1),
    _createVNode(_component_v_btn, {
      class: "mb-5",
      onClick: _ctx.createRoom,
      size: "x-large"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('mainPage.createRoom')), 1)
      ]),
      _: 1
    }, 8, ["onClick"]),
    _createElementVNode("div", null, [
      (_ctx.roomsList && _ctx.roomsList.length)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('mainPage.gamesList')) + ":", 1),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.roomsList, (game, index) => {
              return (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("div", null, _toDisplayString(index + 1) + ".", 1),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      (game.result?.winner)
                        ? (_openBlock(), _createElementBlock("span", {
                            key: 0,
                            class: _normalizeClass([`${game.result.winner}-loyalty-icon`, "mr-1"])
                          }, null, 2))
                        : _createCommentVNode("", true),
                      _createElementVNode("b", null, _toDisplayString(game.host), 1)
                    ]),
                    (_ctx.displayOptions(game.options.roles, game.options.addons))
                      ? (_openBlock(), _createBlock(_component_OptionsPreview, {
                          key: 0,
                          roles: game.options.roles,
                          addons: game.options.addons
                        }, null, 8, ["roles", "addons"]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, _toDisplayString(game.state === 'created' ? `${game.players}/10` : `${game.players} ${_ctx.$t('mainPage.players')}`), 1),
                    _createVNode(_component_v_btn, {
                      color: game.state === 'created' ? undefined : 'info',
                      onClick: ($event: any) => (_ctx.$router.push({ name: 'room', params: { uuid: game.uuid } }))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(game.state === 'created' ? _ctx.$t('mainPage.join') : _ctx.$t('mainPage.watch')), 1)
                      ]),
                      _: 2
                    }, 1032, ["color", "onClick"])
                  ])
                ])
              ]))
            }), 256))
          ], 64))
        : _createCommentVNode("", true)
    ])
  ]))
}