import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-68c4d684"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "game-options" }
const _hoisted_2 = { class: "mr-1 d-flex" }
const _hoisted_3 = {
  key: 0,
  class: "mr-1 d-flex"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PreviewLink = _resolveComponent("PreviewLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedRoles, (role) => {
      return (_openBlock(), _createElementBlock("div", null, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_PreviewLink, {
            target: role,
            text: ""
          }, null, 8, ["target"])
        ])
      ]))
    }), 256)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.addons, (amount, addon) => {
      return (_openBlock(), _createElementBlock("div", null, [
        amount
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_PreviewLink, {
                target: addon,
                text: ""
              }, null, 8, ["target"])
            ]))
          : _createCommentVNode("", true)
      ]))
    }), 256))
  ]))
}