import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8ce7e8b8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex flex-row align-center justify-center" }
const _hoisted_2 = { class: "ml-2 connection-state" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.state.class, "rounded-circle circle"])
    }, null, 2),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.state.text), 1)
  ]))
}