import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-78b2d6a1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_overlay = _resolveComponent("v-overlay")!

  return (_openBlock(), _createBlock(_component_v_overlay, {
    modelValue: _ctx.overlay,
    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.overlay) = $event)),
    persistent: true,
    class: "align-center justify-center"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_sheet, {
            width: "300",
            class: "mx-auto pa-4"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_form, {
                onSubmit: _withModifiers(_ctx.updateUser, ["prevent"]),
                class: "d-flex flex-column align-center justify-center"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t('settings.usernameHint')), 1),
                  _createVNode(_component_v_text_field, {
                    modelValue: _ctx.username,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.username) = $event)),
                    rules: _ctx.rules,
                    label: _ctx.$t('settings.username'),
                    class: "w-100 mb-2"
                  }, null, 8, ["modelValue", "rules", "label"]),
                  _createVNode(_component_v_select, {
                    label: _ctx.$t('settings.language'),
                    items: _ctx.availableLocales,
                    class: "w-100 mb-2",
                    modelValue: _ctx.locale,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.locale) = $event))
                  }, null, 8, ["label", "items", "modelValue"]),
                  _createVNode(_component_v_select, {
                    label: _ctx.$t('settings.colorTheme'),
                    items: _ctx.availableThemes,
                    class: "w-100 mb-2",
                    modelValue: _ctx.colorTheme,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.colorTheme) = $event))
                  }, null, 8, ["label", "items", "modelValue"]),
                  (_ctx.isUserExist)
                    ? (_openBlock(), _createBlock(_component_v_checkbox, {
                        key: 0,
                        modelValue: _ctx.hideSpoilers,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.hideSpoilers) = $event)),
                        "hide-details": true,
                        label: _ctx.$t('settings.hideSpoilersHint')
                      }, null, 8, ["modelValue", "label"]))
                    : _createCommentVNode("", true),
                  (_ctx.isUserExist)
                    ? (_openBlock(), _createBlock(_component_v_checkbox, {
                        key: 1,
                        modelValue: _ctx.hideIndexInHistory,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.hideIndexInHistory) = $event)),
                        "hide-details": true,
                        label: _ctx.$t('settings.hideIndexHint')
                      }, null, 8, ["modelValue", "label"]))
                    : _createCommentVNode("", true),
                  (_ctx.isUserExist)
                    ? (_openBlock(), _createBlock(_component_v_checkbox, {
                        key: 2,
                        modelValue: _ctx.style,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.style) = $event)),
                        "hide-details": true,
                        label: _ctx.$t('settings.animeMode')
                      }, null, 8, ["modelValue", "label"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_v_btn, {
                    disabled: !_ctx.username.length,
                    type: "submit"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.isUserExist ? _ctx.$t('settings.update') : _ctx.$t('settings.submit')), 1)
                    ]),
                    _: 1
                  }, 8, ["disabled"])
                ]),
                _: 1
              }, 8, ["onSubmit"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_btn, {
            onClick: _ctx.closeSettings,
            class: "close",
            icon: "close",
            variant: "text",
            density: "compact"
          }, null, 8, ["onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}